#book h2 {
    margin-bottom: 32px;
    color: #343366;
}

#book section {
    margin: auto auto 32px;
}

#book blockquote {
    margin: auto auto 12px;
}

#book > .info {
    display: flex;
    flex-direction: row;
    gap: 32px;
}

#book > .info > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

#book > .info > div > h2 {
    margin-bottom: 0px;
}

@media (max-width: 900px) {
    #book > .info {
        flex-direction: column;
        align-items: center;
    }    
}

#book a {
    text-decoration: none;
    text-align: center;
    color: white;
    background-color: #343366;

    padding: 12px 20px;
    max-width: 160px;

    font-size: 18px;
    font-weight: bold;
}
