/* ./index.css */

* {
    padding: 0px;
    margin: 0px;
    font-family: Arial, Helvetica
}

/* For webkit browsers like Chrome and Safari */
/* ::-webkit-scrollbar {
    display: none;
} */

/* For Firefox */
/* html {
    scrollbar-width: none;
    -ms-overflow-style: none;
} */

/* For Internet Explorer and Edge */
body {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

/* unvisited link */
/* a:link {
    color: red;
} */
  
/* visited link */
/* a:visited {
    color: green;
} */
  
/* mouse over link */
/* a:hover {
    color: hotpink;
} */
  
/* selected link */
a.blue-active:active {
    color: #3433664a;
}


.app {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    min-width: 320px;

    background-color: rgba(231, 235, 240, 0.5);
}

.app > footer {
    margin-top: auto;
}

.main {
    max-width: 1200px;
    min-width: 240px;
    --main-width: max(100vw, 320px);
    width: calc(var(--main-width) - (32px + 32px));
    
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;

    margin-bottom: 48px;
}

@media (max-width: 600px) {
    .main {
        width: calc(var(--main-width) - (8px + 8px));
        padding-left: 8px;
        padding-right: 8px;
    }
}


.btn {
    margin-top: 1.5em;

    display: inline-block;
    position: relative;
    color: #fff;
    font-size: .9375rem;
    font-weight: 600;
    padding: 12px 10px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    min-width: 145px;
    margin-bottom: 2px;
    transition: background 400ms;
    border: 0;
    border: solid #fff 1px;
    background-color: #343366
}

.btn:hover {
    color: #fff;
    border: solid #fff 1px;
    background-color: #34336673
}

@media only screen and (max-width: 1020px) {
    .btn {
        margin: 1.5em auto 0 auto;
    }
}