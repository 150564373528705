.home img {
    max-width: 100%;
    height: auto
}

.home *,.home ::before,.home ::after {
    box-sizing: border-box
}

.home h3 {
    font-size: 2.5625rem;
}

.home h4 {
    font-size: 2rem;
}

.home .grid {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    width: 100%;
    color: #4c4c4c;
}

.home .grid-item {
    width: calc(50% - 16px);
}

.home .border-right {
    border-right: 1px solid #c2c2c2;
}

.home .training-videos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 32px;
}

.home .grid-item .illustration {
    flex: 0 1;
}

.home .grid-item .content {
    flex: 1;
}


.home .btn {
    margin-top: 1.5em;

    display: inline-block;
    position: relative;
    color: #fff;
    font-size: .9375rem;
    font-weight: 600;
    padding: 12px 10px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    min-width: 145px;
    margin-bottom: 2px;
    transition: background 400ms;
    border: 0;
    border: solid #fff 1px;
    background-color: #343366
}

.home .btn:hover {
    color: #fff;
    border: solid #fff 1px;
    background-color: #34336673
}


@media only screen and (max-width: 850px) {
    
    .home .border-right {
        border: none;
    }

    .home .grid-item {
        width: 100%;
        
        border-top: 1px solid #c2c2c2;
        padding: 16px 0px;
    }

    .home .grid-item:first-of-type {
        border: none;
        padding-top: 0px;
    }
}